// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import 'theme/variables.scss';
// Plus imports for other components in your app.

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$newuatdomain-frontend-portal-primary: mat-palette($mat-indigo);
$newuatdomain-frontend-portal-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$newuatdomain-frontend-portal-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$newuatdomain-frontend-portal-theme: mat-light-theme(
  $newuatdomain-frontend-portal-primary,
  $newuatdomain-frontend-portal-accent,
  $newuatdomain-frontend-portal-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($newuatdomain-frontend-portal-theme);

/* You can add global styles to this file, and also import other style files */

:root {
  --headerBgColor: #34aafd;
  --menuTextColor: #000000;
  --menuBgColor: transparent;
  --activeMenuBgColor: rgba(255, 255, 255, 0.2);
  --darkHeadingColor: #000000;
  --liteHeadingColor: #ffffff;
  --footerBgColor: #34aafd;
  --revolutionSliderBorderColor: #000000;
  --footerTextColor: #ffffff;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

* {
  box-sizing: border-box;
}

.focusable {
  &:focus,
  &:active {
    outline-width: 6px !important;
    outline-style: double !important;
    outline-color: black !important;
  }
}

button[mat-icon-button]:focus,
button[mat-icon-button]:active {
  border-color: transparent !important;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: double 6px white;
    border-radius: inherit;
    padding: 6px;
    mix-blend-mode: difference;
  }
}
button[mat-stroked-button].fillBtn {
  border: 1px solid mat-color($portal-primary, 500);
  color: mat-color($portal-primary, 500);
  &:hover {
    background-color: mat-color($portal-primary, 500);
    color: mat-contrast($portal-primary, 500);
  }
  &:disabled {
    opacity: 0.22;
    background-color: mat-contrast($portal-primary, 500);
    color: mat-color($portal-primary, 500);
  }
}

nav > ul {
  padding: 0;
  > li {
    display: block;
    text-decoration: none;
    text-align: center;
  }
}

mat-ink-bar.mat-ink-bar {
  background-color: var(--menuTextColor) !important;
}

.mobile-view {
  .mat-dialog-container {
    height: initial;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
}

.not-padding .mat-dialog-container {
  padding: 0;
}

.fullscreen {
  min-width: 100%;
  height: 100%;
}

// CUSTOM INPUTS
div.field-wrapper {
  > mat-icon {
    font-size: 22px;
    width: 22px;
    height: 22px;
    margin-right: 11px;
  }
  mat-form-field {
    &.mat-form-field.mat-focused {
      label.mat-form-field-label {
        color: var(--darkHeadingColor);
      }

      span.mat-form-field-ripple {
        background-color: var(--darkHeadingColor);
      }

      div.mat-select-arrow-wrapper > div {
        color: var(--darkHeadingColor);
      }
    }
    &.readonly {
      input:read-only {
        cursor: default;
        color: rgba(mat-contrast($portal-primary, 50), 0.38);
      }
      div.mat-form-field-underline {
        background-image: linear-gradient(
          to right,
          rgba(mat-contrast($portal-primary, 50), 0.42) 0%,
          rgba(mat-contrast($portal-primary, 50), 0.42) 33%,
          transparent 0%
        );
        background-size: 4px 100%;
        background-repeat: repeat-x;
        background-color: transparent;
        span.mat-form-field-ripple {
          background-color: transparent;
        }
      }
    }
    input[matInput][type='number'] {
      text-align: right;
    }
    mat-error {
      height: 15px;
      padding-left: 20px;
      padding-top: 2px;
      color:#BA1236;
      mat-icon {
        font-size: 15px !important;
        width: 15px !important;
        height: 15px !important;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
// SELECT
portal-select.not-underline {
  div.mat-form-field-underline {
    visibility: hidden;
  }
}

mat-option.mat-selected {
  color: var(--darkHeadingColor) !important;
}

// LABEL COLOR
.mat-form-field-should-float {
  span.mat-form-field-label-wrapper {
    overflow: initial;
  }
  mat-label,
  .mat-form-field-label {
    overflow: initial;
    font-size: 16px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    color: var(--darkHeadingColor);
  }
}

// BUTTON STYLES
button[mat-stroked-button],
button[mat-stroked-button],
button[mat-raised-button] {
  border-radius: 16px !important;
  padding: 0 25px !important;
}
button[mat-stroked-button],
a[mat-stroked-button] {
  border-radius: 16px !important;
  padding: 0 25px !important;
  &.primary {
    color: var(--darkHeadingColor) !important;
    &[disabled] {
      color: gray !important;
      background-color: rgb(243, 243, 243) !important;
      cursor: not-allowed;
    }
  }
}
button[mat-raised-button] {
  border-radius: 16px !important;
  padding: 0 25px !important;
  &.primary {
    background-color: var(--headerBgColor) !important;
    color: var(--headerTextColor) !important;
    &[disabled] {
      background-color: lightgray !important;
      color: white !important;
      cursor: not-allowed;
    }
  }
}

// RADIO BUTTON STYLES
mat-radio-button {
  &.mat-radio-checked {
    div.mat-radio-outer-circle {
      border: 2px solid black !important;
    }
    div.mat-radio-inner-circle {
      background-color: black !important;
    }
  }
  div.mat-radio-ripple.mat-ripple > div {
    background-color: black !important;
  }
}

form {
  // CHECKBOX STYLES
  mat-checkbox.mat-checkbox-checked div.mat-checkbox-background {
    background-color: var(--darkHeadingColor) !important;
  }
  mat-checkbox.mat-checkbox-indeterminate div.mat-checkbox-background {
    background-color: var(--darkHeadingColor) !important;
  }

  // RADIO BUTTON STYLES
  mat-radio-button {
    &.mat-radio-checked {
      div.mat-radio-outer-circle {
        border: 2px solid var(--darkHeadingColor) !important;
      }
      div.mat-radio-inner-circle {
        background-color: var(--darkHeadingColor) !important;
      }
    }
    div.mat-radio-ripple.mat-ripple > div {
      background-color: var(--darkHeadingColor) !important;
    }
  }
}

// DIALOG STYLES
h1[mat-dialog-title] {
  font-size: 16px;
}
div[mat-dialog-content] {
  font-size: 14px;
}

// ERROR FORM MESASGE STYLE
div.mat-form-field-subscript-wrapper {
  overflow: unset;
  &[ng-reflect-ng-switch='error'] {
    width: calc(100% + 35px);
  }
}

// YOUTUBE VIDEO PLAYER
// iframe[title='YouTube video player'] {
//   width: 900px;
// }

// Remove form control underline
portal-select.not-underline {
  div.mat-form-field-underline {
    visibility: hidden;
  }
}

svg path.stroked {
  fill: transparent;
  stroke: currentColor;
}

mat-slide-toggle {
  &.mat-checked {
    &.themed {
      div.mat-slide-toggle-bar {
        position: relative;
        background-color: white !important;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--headerBgColor) !important;
          opacity: 0.5;
          border-radius: 8px;
        }
        div.mat-slide-toggle-thumb {
          background-color: var(--headerBgColor) !important;
        }
      }
    }
    div.mat-slide-toggle-bar {
      background-color: #7fcac3 !important;
      div.mat-slide-toggle-thumb {
        background-color: #009588 !important;
      }
    }
    color: #009588;
  }
  &.inactive {
    div.mat-slide-toggle-bar {
      background-color: rgba(#ff5c5c, 0.54) !important;
      div.mat-slide-toggle-thumb {
        background-color: #f34236 !important;
      }
    }
    color: #f34236;
  }
}
// Default menu styles
div.mat-menu-content:not(:empty) {
  padding: 5px;
  button.mat-menu-item {
    height: 48px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    &:hover {
      font-weight: bold;
    }
    &:disabled {
      cursor: not-allowed;
      color: initial;
      font-weight: normal;
      opacity: 0.3;
      &:hover {
        background-color: rgba(black, 0.12);
      }
    }
    chia-icon {
      margin-right: 15px;
    }
  }
}

portal-input div.mat-form-field-infix {
  width: unset;
}

// ROTATION ANIMATION
.transcoding {
  transform: rotate(0deg);
  will-change: transform;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

portal-table.filter {
  filter: blur(2px);
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke:#355ca2 !important;
}

div.loader {
  margin-top: 150px;
}

div.mat-menu-panel {
  max-width: unset;
}

// Note(Danny): Global class, hide content on specific route...
.integrate {
  display: none !important;
}

div.mat-tab-label {
  width: 100%;
  height: 100%;
  &.mat-tab-label-active {
    opacity: 1;
  }
}
